import * as React from 'react';
import '../styles/styles.scss';
import '../styles/cf.css';
import { graphql, useStaticQuery } from 'gatsby';
import Template from '../templates/template';

const query = graphql`
    {
        allStrapiPages(filter: { title: { eq: "Home" } }) {
            nodes {
                route
                title
                content
                noIndex
                metaTitle
                canonicalURL
                nofollow
                metaDescription
            }
        }
    }
`;

function Home() {
    const data = useStaticQuery(query);
    
    const siteMetadata = {
        noIndex: data.allStrapiPages.nodes[0].noIndex,
        metaTitle: data.allStrapiPages.nodes[0].metaTitle ?
            data.allStrapiPages.nodes[0].metaTitle : data.allStrapiPages.nodes[0].title,
        canonicalURL: data.allStrapiPages.nodes[0].canonicalURL ? data.allStrapiPages.nodes[0].canonicalURL : data.allStrapiPages.nodes[0].route,
        nofollow: data.allStrapiPages.nodes[0].nofollow,
        metaDescription: data.allStrapiPages.nodes[0].metaDescription ?
            data.allStrapiPages.nodes[0].metaDescription :
            "Changefirst, the Leading Change Management Company helping Your Business implement Agile Change, and offering a revolutionary new way to meet the challenge!",
        title: data.allStrapiPages.nodes[0].metaDescription.title,
        route: data.allStrapiPages.nodes[0].route
    }

    return (
        <div>
            <Template pageContext={{ data: data.allStrapiPages.nodes[0].content, siteMetadata }} />
        </div>
    );
}

export default Home;
